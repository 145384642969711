.passwordField {

  :global(.text-field__help-actions) {
    zoom: 1;
    padding-left: 8/16+rem;
    border-bottom-right-radius: 4/16+rem;
  }

  .togglePasswordVisibilityIcon {
    display: flex;
    cursor: pointer;
  }
}
