@import '~factor/dist/fonts.css';
@import '~normalize.css/normalize.css';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~factor/src/scss/factor';
@import '~iqm-framework/src/scss/iqm-framework';

@import '../../scss/general';
@import '../../scss/block';
@import '../../scss/select';
@import '../../scss/date-picker';
@import '../../scss/timezone-picker';

.main {
  display: flex;
  padding: 0;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.snackbar__message {
  white-space: pre-wrap;
}

.layout-container {
  width: 100%;
}
