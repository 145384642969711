@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.passwordField {

  &:global(.text-field) {
    margin-top: 3rem;
  }

  :global(.text-field__help-text) {
    margin-top: 1rem;
  }
}

.passwordNote {
  width: 100%;
  @include f1-demi();
  color: $neutral3;
  font-size: 12/16+rem;
  margin-top: 18/16+rem;
  line-height: 1.56;
  word-wrap: break-word;
}

.passwordHints {
  width: 100%;
  @include f1-400();
  color: $neutral3;
  font-size: 12/16+rem;
  margin-top: 12/16+rem;
  line-height: 1.56;
}

.hints {
  margin: 4/16+rem 0 0 24/16+rem;
  list-style-type: disc;

  .hint {
    @include f1-400-2();
    font-size: 10/16+rem;
    line-height: 14/16+rem;
  }

  .green {
    color: $success2;
  }

  .red {
    color: $danger2;
  }
}
