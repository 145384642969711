body {

  .select {
    width: 100%;

    &._opened {
      position: relative;
      z-index: 20;
    }

    &__label {
      display: flex;
      align-items: center;

      .tooltip {
        display: flex;

        path {
          fill: $gray;
        }
      }

      &:empty {
        display: none;
      }
    }

    &._left-label {
      display: flex;
      align-items: center;
    }

    &._left-label {

      .select {

        &__label {
          margin: 0 2rem 0 0;
        }

        &__outer-wrapper {
          flex-grow: 1;
        }
      }
    }
  }
}
