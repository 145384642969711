@import 'src/styles/variables';

@mixin btn {
  position: absolute;
  width: 160/16 + rem;
  height: 35/16 + rem;
  margin-top: 97/16 + rem;
  border-radius: 4/16 + rem;
  float: right;
}

.buttonWrapper {
  :global {
    ._primary.btn-v2 {
      border: 0;
    }
  }
}

.ihpLoginBtn {
  @include btn();
  margin-right: 20/16 + rem;

  &:global(._primary.btn-v2) {
    background-color: $cornflower-blue;

    &:hover {
      background-color: $cornflower-blue;
    }
  }
}

.nonIhpLoginBtn {
  @include btn();
  margin-right: 20/16 + rem;

  &:global(._primary.btn-v2) {
    background-color: $eucalyptus;

    &:hover {
      background-color: $eucalyptus;
    }
  }
}
