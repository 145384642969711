@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.workspaceURLField {

  &:global(.text-field) {
    margin: 3rem 0 0;
    width: 26vw;
    max-width: 24rem;
  }

  :global(.text-field__help-actions) {
    zoom: 1;
    border-bottom-right-radius: 3/16+rem;
    border-top-right-radius: 3/16+rem;
    background-color: $neutral5;
  }

  .suffix {
    @include f1-400-2();
    font-size: 12/16+rem;
    line-height: 1rem;
    padding: 0 12/16+rem;

    @media (max-width: 1400px) {
      padding: 0 8/16+rem;
    }
  }
}
