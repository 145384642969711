@import '~factor/src/scss/variables';

//colors
$titan-white: #ebeeff;
$alto-2: #d1d1d1;
$mercury: #e8e8e8;
$dove-gray-1: #696969;
$mine-shaft-1: #242424;
$table-hover: #d8d8d8;

$manhattan: #f7ce85;

$titan-white: #ebeeff;
$dodger-blue-1: #39f;

$apple: #3f9b34;
$stiletto: #9b3434;

$chip-component-green: #3f9b34;
$chip-component-red: #9b3434;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;
