@import '~factor/src/scss/variables';

//colors
$titan-white: #ebeeff;
$alto-2: #d1d1d1;
$mercury: #e8e8e8;
$dove-gray-1: #696969;
$mine-shaft-1: #242424;
$table-hover: #d8d8d8;
$silver-chalice: #a2a2a2;
$dark-gray: #cbcbcb;

$manhattan: #f7ce85;

$cornflower-blue: #7893ff;
$dark-cerulean: #123787;
$rich-black: #04363d;
$eucalyptus: #3fd4ad;
$celadon-green: #3a827e;
$new-car: #1d58d7;

$titan-white: #ebeeff;
$dodger-blue-1: #39f;
$conflower-blue: #6075ff;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;
