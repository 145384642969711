@import 'src/styles/variables';
@import '~factor/src/scss/mixins';
@import 'scss/mixins';

.passwordNote {
  width: 100%;
  @include f1-demi();
  color: $neutral3;
  font-size: 12/16 + rem;
  margin-top: 18/16 + rem;
  line-height: 1.56;
  word-wrap: break-word;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
